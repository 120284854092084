@import "dropzone/dist/min/dropzone.min.css";
@import "../../../node_modules/trix/dist/trix.css";
.sso-btn:hover path {
  fill: white !important;
}

.oauth-card .card-footer form:nth-of-type(1) .btn {
  @apply rounded-bl rounded-br-none;
}

.prose a.terms {
  @apply text-white;
}

.prose a.terms:hover {
  @apply text-primary;
}

.legal ol {
  counter-reset: item;
}

.legal ol > li {
  counter-increment: item;
}

.legal ol ol > li {
  display: block;
}

.legal ol ol > li:before {
  content: counters(item, ".") ". ";
  @apply -ml-5;
}

.account-and-payout-settings-nav-link {
  position: relative;
}
.account-and-payout-settings-nav-link:hover {
  text-decoration: none;
}
.account-and-payout-settings-nav-link:hover::after {
  display: block;
}
.account-and-payout-settings-nav-link::after {
  content: "";
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -4px;
  border-bottom: 2px solid;
  @apply border-blue-500;
}
.account-and-payout-settings-nav-link.active::after {
  display: block;
}

.pin-inputs {
  /* Firefox */
}
.pin-inputs input::-webkit-outer-spin-button,
.pin-inputs input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.pin-inputs input[type=number] {
  -moz-appearance: textfield;
}

.dropzone {
  min-height: initial;
  border: 0;
  padding: 0;
}

.dropzone .dz-message {
  margin: 0;
}

input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.gig-invitation-accordion .down-arrow {
  display: block;
}
.gig-invitation-accordion .up-arrow, .gig-invitation-accordion .body {
  display: none;
}
.gig-invitation-accordion.expand .up-arrow, .gig-invitation-accordion.expand .body {
  display: block;
}
.gig-invitation-accordion.expand .down-arrow {
  display: none;
}

.sidebar-apps .app-title {
  display: none;
}

.main-sidebar {
  position: fixed;
  top: 4rem;
  width: 20rem;
  z-index: 1000;
}

.container-with-sidebar {
  margin-left: 20rem;
}
.container-with-sidebar .content-actionbar {
  left: 20rem;
}

@media (max-width: 1024px) {
  .main-sidebar {
    left: -20rem;
    transition: all 0.25s ease-out;
  }
  .main-sidebar.is-opened {
    left: 0;
  }
  .container-with-sidebar {
    margin-left: 0;
  }
  .container-with-sidebar .content-actionbar {
    left: 0;
  }
}
.unread-notifications .icon-bell {
  position: relative;
}
.unread-notifications .icon-bell::after {
  content: "";
  @apply absolute -top-1 -right-1 block h-2 w-2 rounded-full bg-white;
}

/*
 * Provides a drop-in pointer for the default Trix stylesheet that will format the toolbar and
 * the trix-editor content (whether displayed or under editing). Feel free to incorporate this
 * inclusion directly in any other asset bundle and remove this file.
 *
 */
.trix-content .attachment {
  max-width: fit-content;
  box-shadow: unset;
  --tw-shadow: unset;
  border-radius: unset;
  flex-basis: 50%;
}
.trix-content .attachment-gallery > action-text-attachment {
  padding-right: 0.5em;
  max-width: fit-content;
}
.trix-content .attachment-gallery .attachment {
  padding: unset;
  max-width: fit-content;
}
.trix-content .attachment-gallery .attachment > img {
  max-width: 100%;
}
.trix-content .attachment__toolbar {
  top: 1rem;
}
.trix-content .attachment--preview {
  width: unset;
}
.trix-content .attachment__caption-editor {
  border-radius: unset;
}
.trix-content strong {
  font-weight: bold;
}

trix-editor .attachment-gallery .attachment {
  margin-right: 0.5em;
}

.custom-input-icon-text {
  font-weight: 500;
  font-size: 1rem;
}

/*# sourceMappingURL=application.sass.css.map */
